@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3 {
  font-family: "Cairo", sans-serif;
}

h1 {
  font-size: 2.3em;
  font-weight: 200;
  text-transform: uppercase;
}

h1 strong {
  font-weight: 500;
}

h2 {
  font-weight: 200;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  display: flex;
  flex: row;
  align-items: center;
}

header h1 {
  flex: 1 0;
}

.app-wrapper {
  padding: 0.5em;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

label {
  display: inline-block;
  min-width: 16em;
  vertical-align: top;
}

input {
  padding: 4px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

button {
  padding: 0.5em 2em;
}
