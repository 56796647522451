ul {
  list-style-type: none;
    padding: 0;
}

ul li {
  padding: 2px;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

nav section{
  width: 50%;
}
